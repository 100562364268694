<template>
    <header class="mb-xxl" :class="{ 'overflow-hidden': !showUSPS }">
        <div class="c w-xl flex flex-wrap align-center justify-between" :class="{ 'pb-xxl': type === 'large' || !showUSPS, 'pt-xxl': !image, 'pt-xl': image }">

            <div class="header__text" :class="textClass[type]">
                <slot name="pretitle" />
                <h2 class="w-10" :class="title_class">{{ title }}</h2>
                <div class="color-secondary" :class="text_class" v-html="text" />
                <Actions
                    v-if="actions"
                    :actions="actions"
                    class="mt-l"
                />
            </div>
            <div class="header__image" :class="imageClass[type]" v-if="image">
                <div class="ratio-5by3 rounded-m shadow-l overflow-hidden">
                    <img
                        v-lazy="image"
                        :data-srcset="image + '-440w 440w, ' + image + '-600w 600w, ' + image + ' 1000w'"
                        :alt="image_title || title"
                        class="ratio-stretch object-cover"
                    />
                </div>
            </div>
        </div>

        <img src="/img/corner_bedrijfsbelang.svg" alt="BedrijfsBelang Logo Icon" title="BedrijfsBelang Logo Icon" class="logo__corner s-down:hide" />

        <USPS v-if="showUSPS && usps.length" :list="usps" class="z-10 relative" />
    </header>
</template>

<script>
import USPS from '@/components/USPS.vue';

export default {
    name: 'Header',
    components: { USPS },
    props: {
        title: String,
        title_class: String,
        text: String,
        text_class: String,
        actions: Array,
        image: String,
        image_title: String,
        type: {
            type: String,
            default: 'small',
        },
        showUSPS: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            textClass: {
                large: 'w-12 m:w-7 l:w-5 xl:w-4 s-down:py-xxl',
                small: 'w-12 m:w-7 l:w-5 xl:w-6 s-down:py-xxl',
            },
            imageClass: {
                large: 'w-12 s-down:mt-xl m:w-5 l-up:w-6',
                small: 'w-12 s-down:mt-xl m:w-5 l-up:w-4',
            },
        };
    },
    computed: {
        usps() {
            const data = this.$store.getters.data;
            if (data && data.usps_nl) {
                return data.usps_nl.split('\n');
            }
            return [];
        },
    },
};
</script>

<style>
nav.absolute + main header {
    padding-top: 8rem;
}
.header__text a:not(.button) {
    text-decoration: underline;
}
</style>
