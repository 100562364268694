<template>
    <Card
        :title="title"
        :action="action"
    >
        <template v-slot:content>
            <div v-for="item in list" :key="item.id" class="w-12">
                <slot name="item" v-bind:item="item" />
            </div>
        </template>
    </Card>
</template>

<script>
export default {
    name: 'ListCard',
    props: {
        list: Array,
        title: String,
        action: Object,
    },
};
</script>

<style>

</style>
