<template>
    <div class="flex justify-center mt-xxl">
        <ul class="usps flex-inline flex-wrap mx-auto align-center justify-center text-nowrap">
            <li v-for="usp in list" :key="usp">
                <span class="color-accent mr-s">
                    <CheckCircle :size="20" />
                </span>
                {{ usp }}
            </li>
            <li>
                <Button to="/contact" class="bg-accent">Contact</Button>
            </li>
        </ul>
    </div>
</template>

<script>
import CheckCircle from 'vue-material-design-icons/CheckCircle.vue';

export default {
    name: 'USPS',
    components: {
        CheckCircle,
    },
    props: {
        list: Array,
    },
};
</script>

<style>
.usps {
    border-radius: 2rem;
    transform: translateY(45%);
    background: var(--color-card);
    box-shadow: var(--shadow-m);
    padding: var(--size-s);
    font-size: var(--size-6)
}
    .usps li:not(:last-child) {
        padding: 0 var(--size-m)
    }
    .usps li:last-child {
        margin-left: var(--size);
    }
    @media (max-width: 40em) {
        .usps li:not(:last-child) {
            padding-top: var(--size);
            padding-bottom: var(--size);
        }
        .usps {
            padding-bottom: var(--size-m);
        }
    }
</style>
