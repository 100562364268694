var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sidebar m-down:flex flex-wrap"},[(_vm.feedback)?_c('Card',{staticClass:"shadow-l w-12 s:w-6 m:w-6 bg-accent",attrs:{"actions":[],"title":"Reactie van de klant:","text":_vm.feedback}}):_vm._e(),(['contact', 'complaints'].includes(_vm.type) && _vm.data.settings && _vm.data.settings[0])?_c('Card',{staticClass:"shadow-s w-12 s:w-6 m:w-6",attrs:{"actions":[],"title":_vm.data.settings[0].company_name}},[_c('div',{staticClass:"accentlink",attrs:{"slot":"content"},slot:"content"},[_c('address',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.formattedAddress)}}),_vm._v("Mail: "),_c('a',{attrs:{"href":'mailto:' + _vm.data.settings[0].company_email}},[_vm._v(_vm._s(_vm.data.settings[0].company_email))]),_c('br'),_vm._v("Tel: "),_c('a',{attrs:{"href":'tel:' + _vm.data.settings[0].company_phone}},[_vm._v(_vm._s(_vm.data.settings[0].company_phone))]),_c('br'),(_vm.data.contact_info_extra_nl)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.$options.filters.nl2br(_vm.data.contact_info_extra_nl))}}):_vm._e()])])]):_vm._e(),(_vm.type !== 'complaints' && _vm.news && _vm.news.length)?_c('ListCard',{staticClass:"shadow-s newsitems w-12 s:w-6 m:w-6",attrs:{"title":"Laatste nieuws","action":{ title: 'Meer nieuws', class: '-s', to: '/nieuws' },"list":_vm.news.slice(0, 3)},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"flex mt-m rounded hover:bg-hover transition",attrs:{"to":{ name: 'news_single', params: { id: item.id } }}},[_c('div',{staticClass:"list__thumb mr-m"},[_c('div',{staticClass:"ratio-5by4 rounded overflow-hidden"},[(item.intro_image)?_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.cdnurl(item.intro_image)),expression:"cdnurl(item.intro_image)"}],staticClass:"ratio-stretch object-cover",attrs:{"data-srcset":_vm.cdnurl(item.intro_image) + '-100w 100w, ' + _vm.cdnurl(item.intro_image) + '-200w 200w, ' + _vm.cdnurl(item.intro_image) + '-440w 440w',"alt":item.name}}):_vm._e()])]),_c('div',{staticClass:"flex-grow flex-shrink leading-xs pt-s"},[_vm._v(" "+_vm._s(item.name)+" "),_c('br'),_c('span',{staticClass:"size-7 color-tertiary"},[_vm._v(_vm._s(_vm.timestamp_to_date(item.created.timestamp)))])])])]}}],null,false,862621339)}):_vm._e(),(!['home', 'complaints'].includes(_vm.type))?_c('ServiceCTAcard',{staticClass:"shadow-s w-12 s:w-6 m:w-6"}):_vm._e(),(_vm.project && !['project', 'complaints'].includes(_vm.type))?_c('Card',{staticClass:"shadow-s w-12 s:w-6 m:w-6",attrs:{"title":_vm.project.intro_title_nl,"text":_vm.project.intro_text_nl,"image":_vm.cdnurl(_vm.project.intro_image),"imageRatio":"ratio-2by1","actions":[
        {
            title: 'Bekijk project',
            class: 'bg-accent -s mb-r',
            to: '/projecten/' + _vm.project.id + '/' + _vm.kebabcase(_vm.project.name),
        },
        {
            title: 'Meer projecten',
            to: 'Projecten'
        }]},scopedSlots:_vm._u([{key:"pretitle",fn:function(){return [_c('div',{staticClass:"size-7 color-tertiary py-s leading-1 flex align-center"},[(_vm.project.location)?[_c('GoogleMapsIcon',{staticClass:"mr-s",attrs:{"size":14}}),_vm._v(" "+_vm._s(_vm.project.location)+" - ")]:_vm._e(),_vm._v(" "+_vm._s(_vm.project.name)+" ")],2)]},proxy:true}],null,false,1660430451)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }