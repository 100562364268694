<template>
    <Card
        class="bg-accent"
        :title="data.services_cta_title_nl"
        :text="data.services_cta_text_nl"
        :action="{ title: 'Gratis dakscan', class: '-s', to: '/contact/scan/' }"
        @click="$router.push('/contact').catch((e) => {});"
    />
</template>

<script>
export default {
    name: 'ServiceCTAcard',
    computed: {
        data() {
            return this.$store.getters.data;
        },
    },
};
</script>
