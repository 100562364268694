<template>
    <div class="sidebar m-down:flex flex-wrap">
        <Card v-if="feedback" class="shadow-l w-12 s:w-6 m:w-6 bg-accent" :actions="[]" title="Reactie van de klant:" :text="feedback" />

        <Card v-if="['contact', 'complaints'].includes(type) && data.settings && data.settings[0]" class="shadow-s w-12 s:w-6 m:w-6" :actions="[]" :title="data.settings[0].company_name">
            <div slot="content" class="accentlink">
                <address>
                    <div v-html="formattedAddress" />Mail:
                    <a :href="'mailto:' + data.settings[0].company_email">{{ data.settings[0].company_email }}</a>
                    <br />Tel:
                    <a :href="'tel:' + data.settings[0].company_phone">{{ data.settings[0].company_phone }}</a>
                    <br />
                    <div v-if="data.contact_info_extra_nl" v-html="$options.filters.nl2br(data.contact_info_extra_nl)" />
                </address>
            </div>
        </Card>

        <ListCard
            v-if="type !== 'complaints' && news && news.length"
            class="shadow-s newsitems w-12 s:w-6 m:w-6"
            title="Laatste nieuws"
            :action="{ title: 'Meer nieuws', class: '-s', to: '/nieuws' }"
            :list="news.slice(0, 3)"
        >
            <template v-slot:item="{ item }">
                <router-link :to="{ name: 'news_single', params: { id: item.id } }" class="flex mt-m rounded hover:bg-hover transition">
                    <div class="list__thumb mr-m">
                        <div class="ratio-5by4 rounded overflow-hidden">
                            <img
                                v-if="item.intro_image"
                                v-lazy="cdnurl(item.intro_image)"
                                :data-srcset="cdnurl(item.intro_image) + '-100w 100w, ' + cdnurl(item.intro_image) + '-200w 200w, ' + cdnurl(item.intro_image) + '-440w 440w'"
                                :alt="item.name"
                                class="ratio-stretch object-cover"
                            />
                        </div>
                    </div>
                    <div class="flex-grow flex-shrink leading-xs pt-s">
                        {{ item.name }}
                        <br />
                        <span class="size-7 color-tertiary">{{ timestamp_to_date(item.created.timestamp) }}</span>
                    </div>
                </router-link>
            </template>
        </ListCard>

        <ServiceCTAcard v-if="!['home', 'complaints'].includes(type)" class="shadow-s w-12 s:w-6 m:w-6" />

        <Card
            v-if="project && !['project', 'complaints'].includes(type)"
            class="shadow-s w-12 s:w-6 m:w-6"
            :title="project.intro_title_nl"
            :text="project.intro_text_nl"
            :image="cdnurl(project.intro_image)"
            imageRatio="ratio-2by1"
            :actions="[
            {
                title: 'Bekijk project',
                class: 'bg-accent -s mb-r',
                to: '/projecten/' + project.id + '/' + kebabcase(project.name),
            },
            {
                title: 'Meer projecten',
                to: 'Projecten'
            }]"
        >
            <template v-slot:pretitle>
                <div class="size-7 color-tertiary py-s leading-1 flex align-center">
                    <template v-if="project.location">
                        <GoogleMapsIcon :size="14" class="mr-s" />
                        {{ project.location }} -
                    </template>
                    {{ project.name }}
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import ListCard from '@/components/ui/ListCard.vue';
import GoogleMapsIcon from 'vue-material-design-icons/GoogleMaps.vue';
import ServiceCTAcard from '@/components/ServiceCTACard.vue';
import { mapGetters } from 'vuex';
import kebabcase from 'lodash.kebabcase';

export default {
    name: 'Sidebar',
    components: { ListCard, GoogleMapsIcon, ServiceCTAcard },
    props: {
        type: String,
        feedback: String,
    },
    computed: {
        ...mapGetters([
            'data',
            'project',
            'news',
        ]),

        formattedAddress() {
            return this.data.settings[0].company_address.formatted.replace(/,/g, '<br />');
        },
    },
    methods: {
        kebabcase,

        timestamp_to_date(val) {
            if (typeof val === 'number') {
                const timestamp = val * 1000;
                const date = new Date(timestamp);
                return new Intl.DateTimeFormat('nl-NL', { day: '2-digit', month: '2-digit' }).format(date);
            }
            return val;
        },
    },
};
</script>

<style>
.newsitems .list__thumb {
    flex: 0 0 25%;
}
.sidebar .card {
    margin-bottom: var(--size-l);
}
</style>
